.chat-room .friend-list .list-group-item {
  margin-bottom: 0;
  border: 0;
  border-bottom: 1px solid;
}

.chat-room .friend-list .list-group-item:last-of-type {
  border-bottom: 0;
}

.chat-room .friend-list .list-group-item.active {
  background-color: #eeeeee;
}

.chat-room .avatar {
  height: 3rem;
  width: 3rem;
}

.chat-room .chat-message:nth-of-type(even) {
  flex-direction: row-reverse;
}

.chat-room .scrollable-friends-list {
  height: 580px;
}

.chat-room .scrollable-chat {
  height: 500px;
  margin-bottom: 15px;
}

.in{
  flex-direction: row-reverse !important;
  display: flex !important;
}

.out{
  flex-direction: row !important;
  display: flex !important;
}

.switch-button_chat{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.switch-space_chat{
  display: flex;
  width: 100%;
  /* height: 100%; */
  justify-content: flex-end;
  align-items: center;
  /* flex-direction: column; */
  flex-wrap: wrap;
}

.switch-space_chat span{
  margin-right: 2%;
  font-size: 0.9rem;
}

.button-space_chat{
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: flex-end;
}

.white-skin .inactive-service__button .btn-default{
  background-color: #d9534f!important;
}

.white-skin .active-service__button .btn-default{
  background-color: #007aa6!important;
}

.title-active_service{
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1rem;
  font-weight: 400;
}