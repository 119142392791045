.body-modal_broadcast-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.white-skin .button-delete_broadcast .btn-default {
  background-color: #d9534f !important;
}

.body-modal_broadcast-buttons span {
  display: flex;
  width: 100%;
  height: 100%;
  white-space: initial;
  overflow: hidden;
}

.footer-modal__broadcast .modal-footer{
  justify-content: center;
}

.edit-broadcast__p {

  white-space: initial;

}
