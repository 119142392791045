:root {
  --alert: #d9534f;
  --sucess: #4bbf73;
  --primary: #307c9e;
  --warning: #fcb450;
  --gray1: #545454;
  --gray2: #d9d9d9;
  --gray3: #e9eaec;
  --white: #ffffff;
  --white_2: #fafafa;
}

/* LEFT BOX */

.container-left__elements {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  align-content: center;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.container-left__item {
  background-color: var(--white);
  border-bottom: 1px solid var(--white_2);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
}

.container-left__item-link {
  background-color: var(--white);
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
}

.container-left__item-link-s {
  background-color: var(--white);
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  border: 0;
  border-bottom: 2px solid var(--white_2);
  border-left: 4px solid var(--primary);
  z-index: 10;
}

.container-left__item-link:hover {
  background-color: var(--white);
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  border: 0;
  border-bottom: 2px solid var(--white_2);
  border-left: 4px solid var(--primary);
  z-index: 10;
}

.container-left__item-link span {
  padding: 0;
  width: 100%;
  height: 100%;
  padding-left: 6%;
  padding-top: 4%;
  font-size: 12px;
  color: var(--gray1);
  font-weight: 400;
}

.container-left__item-link-s span {
  padding: 0;
  width: 100%;
  height: 100%;
  padding-left: 6%;
  padding-top: 4%;
  font-size: 12px;
  color: var(--gray1);
  font-weight: 400;
}

/* RIGHT BOX */

.container-right__data {
  margin: 7px 20px 20px 20px;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* INPUT FORMS */

.container-right__data .md-form {
  margin-bottom: 0 !important;
  margin-top: 13px !important;
}

.container-right__data .md-form label.active {
  font-size: 11px;
}

.container-right__data .md-form input[type="text"]:not(.browser-default) {
  font-size: 14px;
}

.container-right__data
  .md-form
  input[type="text"]:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid var(--primary);
  box-shadow: 0 1px 0 0 var(--primary);
}

.container-right__data
  .md-form
  input[type="text"]:not(.browser-default):focus:not([readonly])
  + label {
  color: var(--gray1);
}

.md-form input:not([type]):focus:not([readonly]),
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
.md-form
  input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="phone"]:not(.browser-default):focus:not([readonly]),
.md-form input[type="search-md"]:focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--primary);
  box-shadow: 0 1px 0 0 var(--primary);
}

.white-skin input[type="email"]:focus:not([readonly]),
.white-skin input[type="text"]:focus:not([readonly]),
.white-skin input[type="password"]:focus:not([readonly]),
.white-skin input[type="number"]:focus:not([readonly]),
.white-skin textarea.md-textarea:focus:not([readonly]) {
  border-color: var(--primary);
}

.md-form input:not([type]):focus:not([readonly]) + label,
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]) + label,
.md-form
  input[type="password"]:not(.browser-default):focus:not([readonly])
  + label,
.md-form
  input[type="email"]:not(.browser-default):focus:not([readonly])
  + label,
.md-form input[type="url"]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type="time"]:not(.browser-default):focus:not([readonly]) + label,
.md-form input[type="date"]:not(.browser-default):focus:not([readonly]) + label,
.md-form
  input[type="datetime"]:not(.browser-default):focus:not([readonly])
  + label,
.md-form
  input[type="datetime-local"]:not(.browser-default):focus:not([readonly])
  + label,
.md-form input[type="tel"]:not(.browser-default):focus:not([readonly]) + label,
.md-form
  input[type="number"]:not(.browser-default):focus:not([readonly])
  + label,
.md-form
  input[type="search"]:not(.browser-default):focus:not([readonly])
  + label,
.md-form
  input[type="phone"]:not(.browser-default):focus:not([readonly])
  + label,
.md-form input[type="search-md"]:focus:not([readonly]) + label,
.md-form textarea.md-textarea:focus:not([readonly]) + label {
  color: var(--primary);
}

/* BUTTON SAVE */

.button-save__form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.button-save__form .button-save {
  background-color: var(--primary) !important;
  margin: 0;
  width: 120px;
  height: 36px;
  align-items: center;
  padding: 0;
  font-size: 12px;
  border-radius: 5px;
}

.white-skin
  .button-save__form
  .btn-default:not([disabled]):not(.disabled):active,
.white-skin .btn-default:not([disabled]):not(.disabled).active,
.show > .white-skin .btn-default.dropdown-toggle {
  background-color: var(--primary) !important;
  /* box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%); */
}

/* BUTTON DELETE */

.button-delete__form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.button-delete__form .button-delete {
  background-color: var(--alert) !important;
  margin: 0;
  width: 120px;
  height: 36px;
  float: right;
  align-items: center;
  padding: 0;
  font-size: 12px;
  border-radius: 5px;
}

.white-skin
  .button-delete__form
  .btn-default:not([disabled]):not(.disabled):active,
.white-skin .btn-default:not([disabled]):not(.disabled).active,
.show > .white-skin .btn-default.dropdown-toggle {
  background-color: var(--alert) !important;
  /* box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%); */
}

/* FIELD INPUT ICON */

.field-input__icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.field-input__icon .md-form {
  flex-grow: 1;
}

.field-input__icon .far {
  color: var(--alert);
}

/* MODAL */
.modal-buttons {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-buttons .button-cancel {
  background-color: var(--gray1) !important;
  width: 120px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.modal-buttons .button-save {
  background-color: var(--primary) !important;
}

.modal-buttons .button-delete {
  background-color: var(--alert) !important;
  width: 120px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.modal-delete__title {
  margin: 0;
  font-weight: 400;
}

.modal-delete__information {
  font-weight: 300;
}

/* SELECT */

/* SELECT */

.container-right__data .select-wrapper > label.mdb-main-label {
  font-size: 14px;
}
.container-right__data .select-container .md-form label.active {
  font-size: 10px;
}
.container-right__data .select-container .select-wrapper.md-form > ul li label {
  color: var(--white);
}

.white-skin input[type="checkbox"]:checked + label:before {
  border-right: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
}

.container-right__data .dropdown-content li > a,
.dropdown-content li > span {
  color: var(--primary);
}
.container-right__data .dropdown-content li,
.dropdown-content li.active {
  color: var(--gray1);
  background-color: var(--white);
}

.container-right__data .dropdown-content li:hover,
.dropdown-content li.active {
  color: var(--white);
  background-color: var(--primary);
}
.container-right__data .dropdown-content li > a,
.dropdown-content li > span {
  color: inherit;
  font-weight: 500;
  font-size: 12px;
}

/* TRANSLATE */
.container-data__translate {
  height: 100%;
  width: 100%;
  margin-top: 13px;
}

.container-data__translate span {
  font-size: 12px;
  font-weight: 500;
}

/* DELETE */
.container-data__delete {
  height: 100%;
  width: 100%;
  margin-top: 13px;
}

.container-data__delete span {
  font-size: 12px;
  font-weight: 500;
}
