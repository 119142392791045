.icon-product {
  display: flex;
  /* width: 100%;
  height: 100%; */
  flex-direction: row;
  align-items: center;
}

.icon-product a {
  color: white;
}

.icon-product a:hover {
  text-decoration: underline;
}

.icon-product .fa-2x {
  font-size: 1.5em;
  margin-right: 0.5rem;
}

.icon-product span {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.edit-product {
  display: flex;
  width: 100%;
  height: 80vh;
  flex-direction: row;
  box-sizing: border-box;
  /* background-color: lawngreen; */
}

.edit-product__left-box {
  display: flex;
  width: 33%;
  height: 90%;
  flex-direction: row;
  padding: 0rem 0.4rem 0.3rem 0;
  /* background-color: rebeccapurple; */
}

.edit-product__right-box {
  display: flex;
  width: 67%;
  height: 90%;

  flex-direction: row;
  padding: 0rem 0rem 0.3rem 0.4rem;
  /* background-color: red; */
}

.edit-product__left-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  /* background-color: rebeccapurple; */
  box-shadow: 0.2rem 0.1rem 0.3rem rgb(0, 0, 0, 0.1);
}

.edit-product__right-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;
  /* background-color: red; */
  box-shadow: -0.2rem 0.1rem 0.3rem rgb(0, 0, 0, 0.1);
}

/* ::-webkit-scrollbar {
  width: 0.2rem;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1; 
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #307c9e; 
} */

.edit-product__textarea {
  /* display: flex; */
  margin-top: 1.3rem;
  /* width: 100%;
  height: 100%; */
}

/* .edit-product__right-container .modal-body {
  display: flex;
  width: 100%;
  height: 100%;
} */

/* .edit-product__right-container .text-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
} */

.list-config {
  margin: 0;
  padding: 0;
  /* background-color: var(--white); */
  align-content: center;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.list-config__item {
  /* background-color: var(--white); */
  border-bottom: 2px solid #fafae5;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  height: 3rem;
}

.list-config__item-a {
  /* background-color: var(--white);
  color: var(--white); */
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.list-config__item-a-s {
  background-color: #307c9e;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.list-config__item-a-s span {
  color: white;
  margin-left: 1.7rem;
}

/* .list-config__item-a:hover {
  background-color: #307c9e;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  
} */

/* .list-config__item-a:hover span {
  color: white;
} */

.list-config__item-a span {
  color: #545454;
  margin-left: 1.7rem;
}

.list-edit__text-switch {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  /* align-items: center; */
}

.list-edit__text-switch span {
  display: flex;
  width: 17rem;
}

.list-edit__text-switch .switch {
  margin-left: 1rem;
}

.list-edit__text-switch .switch label {
  font-size: 0.8rem;
  margin: 0;
}

.list-edit__text-switch .switch label .lever {
  background-color: #e9eaec;
}

.list-edit__text-switch .switch label input[type="checkbox"]:checked + .lever {
  background-color: #e9eaec;
}

.edit-product__right-container .scroll_select ul {
  height: 10rem !important;
}

.edit-product__button {
  display: flex;
  /* height: 100%; */
  width: 100%;
  justify-content: flex-end;
}

.edit-product__button .btn {
  margin: 0;
}

.modal-buttons {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-buttons .button-cancel {
  background-color: #d9534f !important;
  width: 7.5rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.modal-buttons .button-save {
  background-color: #307c9e !important;
  width: 7.5rem;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
