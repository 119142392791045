.line_a {
  border-bottom: 1px solid rgba(205, 205, 205, 0.762);
  height: 80px;
}

.color_select {
  background-color: #007aa6 !important;
}

.color_select_error {
  background-color: #da351f !important;
}

.color_btn {
  background-color: #ff674c;
}

.novalidate_select {
  display: block;
  width: 100%;
  margin-top: -0.5rem;
  font-size: 80%;
  color: #dc3545;
}

.valid-feedback_ok {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
  margin-top: -20px;
}

.btn_ {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.side-nav_p a {
  display: block;
  height: auto !important;
  padding-left: 20px;
  font-size: 1rem;
  line-height: 20px;
}

.color_text_btn i {
  color: white !important;
}

.color_text_btn div {
  color: white !important;
}

.th-size-col-10 {
  width: 10%;
}

.th-size-col-15 {
  width: 10%;
}
.th-size-col-50 {
  width: 40%;
}

.th-size-col-20 {
  width: 10%;
}

.btn-square {
  padding: 0.8rem 1rem 0.8rem 1rem;
}

.btn-square-file {
  padding: 0.4rem 0.4rem;
  font-size: 1.81rem;
}

.center-button {
  text-align: center;
  padding: 0;
}

.color_btn-red {
  background-color: #da351f !important;
  color: white;
}

.color_btn-yellow {
  background-color: #fbae00;
  margin: 0;
  color: white;
}

.btn:hover {
  color: white;
  text-decoration: none;
}

.color_btn-c {
  background-color: #00aca8;
}

.color_btn-h {
  background-color: #6a7373f7;
}
.color_btn-d {
  background-color: #027ea8;
  color: #ffffff !important;
}
.color_btn-f {
  background-color: #ed6217;
  color: #ffffff !important;
}
.color_btn-g {
  background-color: #007aa6 !important;
  color: #ffffff !important;
}
.size_icon {
  font-size: 1.3em;
}

.table_warp td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* padding: 0 1rem 0 1rem;*/
}

.options_ {
  position: unset;
  display: inline;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 3rem 3rem 3rem;
}

.modal-footer_b {
  display: block;
}

.md-form .form-control.is-invalid {
  border-color: #f44336 !important;
}

.md-form {
  position: relative;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.novalidate_file {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  margin-top: -10px;
  text-align: end;
}
.novalidate_html {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
}

.text-primary {
  color: #757575 !important;
}

.text_1 {
  color: #495057;
}

.spinner-border {
  width: 1rem !important;
  height: 1rem !important;
}

.btn_save {
  padding: 0.5, 4rem 2.14rem !important;
}

.img_overflow {
  height: 20rem;
  overflow: auto;
}
@media only screen and (min-width: 768px) {
  .th-size-col-20 {
    width: 30%;
  }
}

@media only screen and (min-width: 992px) {
  .th-size-col-20 {
    width: 13%;
  }
}

@media only screen and (min-width: 1024px) {
  .th-size-col-20 {
    width: 20%;
  }
}

/* clases editor de texto*/

.ocultar {
  display: none !important;
}

.popup .rdw-colorpicker-modal-header {
  display: none !important;
}
.popup .rdw-colorpicker-modal-options {
  overflow: auto;
}

.popup {
  height: auto !important;
  padding: 5px !important;
  width: 70px !important;
}

.editor-class {
  border-radius: 2px;
  border: 1px solid #e3e3e3;
  height: 150px !important;
}

.validar_campo {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.novalidar_campo {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.md-form .validate {
  margin-bottom: 0rem;
}

.show_incorrect {
  display: block;
  width: 100%;
  margin-top: -0.8rem;
  font-size: 80%;
  color: #dc3545;
}

.noshow_incorrect {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.show_correct {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.g-recaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

.switch-color label input[type="checkbox"]:checked + .lever:after {
  background-color: #027ea8;
}

.scroll_select ul {
  height: 300px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}
/* @media screen and (max-width: 500px){ 
    .g-recaptcha {
    transform:scale(0.77);
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    } 
    } */

.MuiPickersToolbar-toolbar {
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: #007aa6 !important;
}

.bigbutton {
  padding: 0 !important;
  color: #e3e3e3 !important;
  background-color: #e3e3e3 !important;
}

.help-button {
  color: #007aa6;
}

.view-more {
  color: white;
}

.view-more:hover {
  color: white;
  text-align: center;
}

.view-more .preloader-wrapper {
  width: 1rem;
  height: 1rem;
}

.text-left .view-more .preloader-wrapper .spinner-red,
.preloader-wrapper .spinner-red-only {
  border-color: white !important;
}

.preloader-wrapper .spinner-blue,
.preloader-wrapper .spinner-blue-only {
  border-color: #007aa6;
}

.button-broadcastMessage {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  justify-content: center;
}

.button-broadcastMessage .button-broadcastMessage_square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  min-height: 2.5rem;
  padding: 0;
  /* padding: 0.8rem 1rem 0.8rem 1rem; */
}

.container-phonenumber_test {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5%;
  flex-direction: column;
}

/* .container-phonenumber_area-code {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
} */
.container-phonenumber_test .react-tel-input {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  position: relative;
  width: auto;
}

.invalid-phone-number {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d9534f;
}

.body-message__send-test p {
  white-space: normal;
}

.scrollbar_booking-systems .fadeElement.fadeIn {
  max-height: 200px;
}

.center-button .btn-square-dropdown {
  padding: 0.26rem;
}

.md-form .delete_white-space {
  white-space: normal;
}
