.switch label input[type="checkbox"]:checked+.lever{
    background-color: #307C9E ;
}

.switch label input[type="checkbox"]:checked+.lever:after {
    left: 1.5rem;
    background-color: #307C9E ;
}

.switch label .lever{
    margin-left: 7px ;
    margin-right: 6px ;
}

.switch label{
    font-size: 10px ;
}